<template>
  <content-wrapper :title="labels.LBL_BANNERS" back>
    <template slot="actions">
      <el-button size="small" type="success" @click="submitForm('bannerForm')">
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_UPDATE }}
      </el-button>
    </template>

    <el-form
      v-loading="loading"
      ref="bannerForm"
      :model="banner"
      :rules="rules"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="image" :label="labels.LBL_PHOTO">
            <UploadImage v-model="banner.image" :expand="true" />
          </el-form-item>
        </el-col>

        <el-col :span="16">
          <el-form-item prop="title" :label="labels.LBL_TITLE">
            <rich-editor v-model="banner.title" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="labels.LBL_PUBLISH_BANNER">
            <el-switch v-model="banner.is_published"></el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item :label="labels.LBL_SUBTITLE">
            <rich-editor v-model="banner.subtitle" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </content-wrapper>
</template>

<script>
  import { mapState } from "vuex";
  import { labels, rules } from "@/common";
  import Banner from "@/models/banner";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper.vue";
  import UploadImage from "@/components/UploadImage";
  import RichEditor from "@/components/RichEditor";

  export default {
    name: "EditBanner",

    components: {
      ContentWrapper,
      UploadImage,
      RichEditor,
    },

    props: {
      bannerId: {
        type: [String, Number],
        required: true,
      },
    },

    data() {
      return {
        labels,
        rules,

        banner: new Banner(),
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        bannerDetail: (state) => state.banner.bannerDetail,
      }),
    },

    watch: {
      bannerId: {
        immediate: true,
        handler(nv) {
          this.loadBannerDetail(nv);
        },
      },

      bannerDetail: {
        handler(nv) {
          this.setBannerDetail(nv);
        },
      },
    },

    methods: {
      loadBannerDetail(id) {
        this.$store.dispatch("banner/getBannerDetail", id);
      },

      setBannerDetail(detail) {
        this.banner = detail;
        this.banner.is_published = !!detail.is_published;
        this.banner.image = detail.image_url;
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch("banner/updateBanner", this.banner);
          }
        });
      },
    },
  };
</script>

<style></style>
